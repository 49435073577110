// import Dashboard from 'views/Shipments/SalesDashboard/components/Dashboard'

// const BASE_URL = process.env.REACT_PUBLIC_BASE_URL;
const BASE_URL = 'https://api.shipcluescargo.com'

export const config = {
    authentication: {
        signIn: `${BASE_URL}/auth/login/`,
        signUp: `${BASE_URL}/auth/register/`,
        refreshToken: `${BASE_URL}/auth/refresh-token/`,
    },

    otp: {
        sendOtp: `${BASE_URL}/auth/send-otp/`,
        verifyOtp: `${BASE_URL}/auth/verify-otp/`,
    },

    recharge: {
        createOrder: `${BASE_URL}/transactions/create/order/shipcargo/`,
        verifyPayment: `${BASE_URL}/transactions/shipcargo/verify/payment/`,
        transactionhistory: `${BASE_URL}/wallets/transactions`,
    },

    dashboard: {
        Shipments: `${BASE_URL}/shipcargo/shipments/api/status-count/`,
    },

    warehouse: {
        fetchWarehouse: `${BASE_URL}/warehouse/get_by_seller/`,
        addWarehouse: `${BASE_URL}/warehouse/create_warehouse/`,
    },

    ratecard: {
        vasapi: `${BASE_URL}/pricing/get-ratecard/`,
    },

    kyc: {
        iskyccheck: `${BASE_URL}/shipcargo/sellers/check-kyc-status/`,
        companydetails: `${BASE_URL}/shipcargo/sellers/kyc/company-details/`,
        businessdetails: `${BASE_URL}/shipcargo/sellers/kyc/business-details/`,
        bankaccountdetails: `${BASE_URL}/shipcargo/sellers/kyc/bank-details/`,
    },

    kycget: {
        businessdetailsget: `${BASE_URL}/shipcargo/sellers/get-business-details/`,
    },

    shipment: {
        create: `${BASE_URL}/shipcargo/shipments/create-shipment/`,
        courierRates: `${BASE_URL}/serviceability/courier-rates/`,
        fullfill: `${BASE_URL}/shipcargo/shipments/fullfill/`,
        remarks: `${BASE_URL}/shipcargo/shipments/receiver/`,
        printlabale: `${BASE_URL}/shipcargo/shipments/shipment-label/160/?format=pdf`,
        allshipment: `${BASE_URL}/shipcargo/shipments/status/list/`,
        editShipment: `${BASE_URL}/shipcargo/shipments/api/`,
        pendingpickup: `${BASE_URL}/shipcargo/shipments/pickup_pending`,
        pickupschedule: `${BASE_URL}/shipcargo/shipments/get_all/status_details/?status=pickup_scheduled`,
        intransit: `${BASE_URL}/shipcargo/shipments/get_all/status_details/?status=in_transit`,
        rto: `${BASE_URL}/shipcargo/shipments/get_all/status_details/?status=rto`,
        delivered: `${BASE_URL}/shipcargo/shipments/get_all/status_details/?status=delivered`,
        allshipments: `${BASE_URL}/shipcargo/shipments/all_status_shipments`,
        shipmentpreviews: `${BASE_URL}/shipcargo/shipments/api/`
    },

    imageUpload: `${BASE_URL}/shipcargo/shipments/upload-image/`,
}
